
<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select
					size="small"
					v-if="isAdmin"
					@change="agentChange"
					v-model="query.agentId"
					filterable
					clearable
					placeholder="代理商"
					class="handle-select mr10"
					style="width: 260px;"
				>
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					v-if="isAgent"
					style="width: 260px"
					v-model="query.companyId"
					@change="fileChange"
					filterable
					clearable
					placeholder="企业"
					class="handle-select mr10"
				>
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<el-tabs v-model="query2.customerFileId" type="card" @tab-click="handleClickNav" style="overflow: auto;" closable @tab-remove="deleteTab">
				<el-tab-pane :label="item.name" :name="item.id" v-for="item in menuList" :key="item.type"></el-tab-pane>
			</el-tabs>
			<el-collapse-transition>
				<el-form ref="queryList" :model="query2" label-width="90px" label-position="left" class="query-list" v-if="showForm">
					<el-form-item label="跟进状态:" style="margin-top: 15px;">
						<el-radio-group v-model="query2.called" :key="calledKey">
							<el-radio label="true" @click.enter.prevent="getcalled('true')">已拨打</el-radio>
							<el-radio label="false" @click.enter.prevent="getcalled('false')">未拨打</el-radio>
						</el-radio-group>
					</el-form-item>
					<div v-if="findNormalList.length > 0">
						<el-form-item label="分配员工:" v-if="isNormal == false">
							<el-radio-group v-model="query2.normalId" :key="normalIdKey">
								<el-radio v-for="item in findNormalList" :key="item" :label="item.label" @click.enter.prevent="getnormalId(item.label, item.value)">
									{{ item.label }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</div>
					<el-form-item label="创建时间:">
						<el-date-picker
							v-model="timeArr"
							style="width: 260px"
							type="daterange"
							unlink-panels
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							@change="getTime()"
							size="small"
						></el-date-picker>
					</el-form-item>
					<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="getphoneNumberPoolDataList" v-show="showForm">搜索</el-button>
				</el-form>
			</el-collapse-transition>
			<div class="loyo-folding-btn loadMore" v-if="showForm == true" @click="showForm = !showForm">
				<a href="javascript:;" data-toggle="tooltip" data-placement="right" title="" data-original-title="展开 "><i class="el-icon-arrow-up"></i></a>
			</div>
			<div class="loyo-folding-btn close__Btn down2" v-else @click="showForm = !showForm">
				<a href="javascript:;" data-toggle="tooltip" data-placement="right" title="" data-original-title="收起"><i class="el-icon-arrow-down"></i></a>
			</div>

			<el-button size="small" type="primary" :class="[theme]" class="ml10" style="margin-bottom: 20px;" @click="allocationData" v-if="isNormal == false">分配线索</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus" @click="addCluePool" v-if="isNormal == false">新建线索池</el-button>

			<div class="data-pool-operation">
				<p>
					已选
					<span>{{ multipleSelectionlen }}</span>
					条线索
				</p>
				<el-button size="mini" icon="el-icon-delete" @click="deleteDataPhoneNumberPool">删除</el-button>
				<el-button size="mini" icon="el-icon-s-promotion" @click="fetchDefaultUsers">转移</el-button>
				<el-button size="mini" icon="el-icon-upload2" @click="exportDataPhoneNumberPool">导出</el-button>
			</div>
			<div>
				<el-table
					v-if="clueType == 'leads'"
					:data="tableData"
					border
					class="table"
					ref="multipleTable"
					header-cell-class-name="table-header"
					:header-cell-style="{ 'text-align': 'center' }"
					@selection-change="handleSelectionChange"
					:row-style="{ height: '20px' }"
					:cell-style="{ padding: '6px' }"
					:row-class-name="tableRowClassName"
					:tree-props="{ children: 'children' }"
					@row-click="showData"
					:tooltip-effect="'tooltipStyle'"
				>
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column prop="juridicalPerson" label="客户姓名" width="100" align="center"></el-table-column>
					<el-table-column prop="companyName" label="公司名称" align="center"></el-table-column>

					<el-table-column prop="contacts" label="联系方式" align="center" style="position: relative;" width="200">
						<template #default="scope">
							<div class="contacts-box">
								 <el-tag size="medium" class="contacts1" @click.stop="getstop" v-if="scope.row.contacts[0]">{{ scope.row.contacts[0].contactInfo }}</el-tag>
								  <div class="big"><div class="small"></div></div>
								 <div class="contacts-info">
									<div
										style="display: flex;flex-direction: row;justify-content:space-between;	align-items: center;background-color: #faf7f0;margin-bottom: 6px;border-radius: 6px;padding: 6px;"
										v-for="item in scope.row.contacts"
										:key="item.contactInfo"
									>
										<div class="contacts-phone">
											<p class="phone">{{ item.contactInfo }}<p
												v-if="item.recommend == true"
												style="background: red;color: #fff;border-radius: 50%;width: 22px;height: 22px;line-height: 22px;text-align: center;margin-left: 4px;transform: scale(0.8);-webkit-transform: scale(0.8);"
											>
												荐
											</p></p>
											<p class="name" v-if="item.contactPerson">{{ item.contactPerson }}	</p>
										</div>
										<div style="display: flex;align-items: center;">
											<p v-if="item.called == false" style="color: #549DFC;">未拨打</p>
											<p v-else style="color: #68D641;">已拨打</p>
											<img src="../assets/img/phone.png" @click.stop="handleSocketSend(item, scope.row)" />
										</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="called" label="跟进状态" align="center">
						<template #default="scope">
							<p
								v-if="scope.row.called == false"
								style="background-color: #549DFC;color: #fff;padding: 2px 4px;border-radius: 2px;width: 70px;text-align: center;margin: 0 auto;"
							>
								未拨打
							</p>
							<p v-else style="background-color: #68D641;color: #fff;padding: 2px 4px;border-radius: 2px;width: 70px;text-align: center;margin: 0 auto;">已拨打</p>
						</template>
					</el-table-column>
					<el-table-column prop="normalName" width="120" label="分配员工" align="center"></el-table-column>
					<el-table-column prop="createdAt" label="创建时间" width="200" align="center"></el-table-column>
					<el-table-column prop="updatedAt" label="最后跟进时间" width="200" align="center"></el-table-column>
					<el-table-column label="操作" align="center" width="120" fixed="right">
						<template #default="scope">
							<el-button size="small" type="text" @click.stop="handleEdit(scope.row)">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				
				
				<el-table
					v-if="clueType == 'shop'"
					:data="tableData"
					border
					class="table"
					ref="multipleTable"
					header-cell-class-name="table-header"
					:header-cell-style="{ 'text-align': 'center' }"
					@selection-change="handleSelectionChange"
					:row-style="{ height: '20px',position: 'relative'}"
					:cell-style="{ padding: '6px' }"
					:row-class-name="tableRowClassName"
					:tree-props="{ children: 'children' }"
					@row-click="showData"
					style="width: 100%;"
					
				>
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column prop="shopName" label="店铺名称" align="center"></el-table-column>
					<el-table-column prop="contacts" label="联系方式" align="center" style="position: relative;" width="200">
						<template #default="scope">
							<div class="contacts-box">
								<el-tag size="medium" class="contacts1" @click.stop="getstop" v-if="scope.row.contacts[0]">{{ scope.row.contacts[0].contactInfo }}</el-tag>
								<div class="big"><div class="small"></div></div>
								<div class="contacts-info">
									<div
										style="display: flex;flex-direction: row;justify-content:space-between;	align-items: center;background-color: #faf7f0;margin-bottom: 6px;border-radius: 6px;padding: 6px;"
										v-for="item in scope.row.contacts"
										:key="item.contactInfo"
									>
										<div class="contacts-phone">
											<p class="phone">{{ item.contactInfo }}<p
												v-if="item.recommend == true"
												style="background: red;color: #fff;border-radius: 50%;width: 22px;height: 22px;line-height: 22px;text-align: center;margin-left: 4px;transform: scale(0.8);-webkit-transform: scale(0.8);"
											>
												荐
											</p></p>
											<p class="name" v-if="item.contactPerson">{{ item.contactPerson }}</p>
										</div>
										<div style="display: flex;align-items: center;">
											<p v-if="item.called == false" style="color: #549DFC;">未拨打</p>
											<p v-else style="color: #68D641;">已拨打</p>
											<img src="../assets/img/phone.png" @click.stop="handleSocketSend(item, scope.row)" />
										</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="called" label="跟进状态" align="center" width="120">
						<template #default="scope">
							<p
								v-if="scope.row.called == false"
								style="background-color: #549DFC;color: #fff;padding: 2px 4px;border-radius: 2px;width: 70px;text-align: center;margin: 0 auto;"
							>
								未拨打
							</p>
							<p v-else style="background-color: #68D641;color: #fff;padding: 2px 4px;border-radius: 2px;width: 70px;text-align: center;margin: 0 auto;">已拨打</p>
						</template>
					</el-table-column>
					<el-table-column prop="normalName" width="120" label="分配员工" align="center"></el-table-column>
					<el-table-column prop="address" label="店铺地址" align="center"></el-table-column>
					<el-table-column label="操作" align="center" width="120" fixed="right">
						<template #default="scope">
							<el-button size="small" type="text" @click.stop="handleEdit(scope.row)">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination">
					<el-pagination
						background
						layout="total, sizes, prev, pager, next, jumper"
						:current-page="query2.pageIndex"
						:page-sizes="[100, 500, 1000, 2000]"
						:page-size="query2.pageSize"
						:total="pageTotal"
						@size-change="handleSizeChange"
						@current-change="handlePageChange"
					></el-pagination>
				</div>
			</div>
		</div>

		<el-dialog title="新建线索池" v-model="addVisible" width="450px" @close="closeDialog('formAdd')" :close-on-click-modal="false">
			<el-form :model="formAdd" ref="formAdd" label-width="100px">
				<el-form-item label="线索名称" :prop="name"><el-input size="small" v-model="formAdd.name"></el-input></el-form-item>
				<el-form-item label="线索类型" :prop="type">
					<el-select size="small" style="width: 100%" v-model="formAdd.type" filterable clearable class="handle-select">
						<el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="dialog-footer myRight">
				<el-button size="small" @click="addVisible = false">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getaddCreatePool('formAdd')">确定</el-button>
			</div>
		</el-dialog>
		<div class="drawer-box" :modal-append-to-body="false" append-to-body>
			<el-drawer title="客户详情" v-model="drawerVisible" direction="rtl" size="60%" :before-close="handleClose" modal="false" :with-header="false">
				<iframe id="iframe" :src="iframeURL" style="height: 100%; width: 100%;" frameborder="0"></iframe>
			</el-drawer>
		</div>

		<el-dialog title="分配线索" v-model="allocationVisible" width="650px" @close="closeDialog('formAllocation')" :close-on-click-modal="false">
			<el-form :model="formAllocation" ref="formAllocation" label-width="100px">
				<el-form-item label="分配数量" prop="type">{{ noDistribution }}</el-form-item>
				<el-form-item label="分配类型" prop="type">
					<el-radio-group v-model="formAllocation.type" @change="typeChange">
						<el-radio label="avg">平均分配</el-radio>
						<el-radio label="distribution">手动分配</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="部门" prop="departmentList">
					<el-checkbox-group v-model="formAllocation.departmentList" @change="handleCheckedCitiesChange" size="small">
						<el-checkbox v-for="item in departmentList" :label="item.value" :key="item.value" border style="margin-bottom:10px;margin-left: 10px;">
							{{ item.label }}
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>

				<el-form-item label="员工" prop="nameList" v-if="nameList.length > 0">
					<el-checkbox-group v-model="formAllocation.nameList" @change="handleCheckedCitiesChange2" size="small">
						<el-checkbox
							v-for="(item, index) in nameList"
							:label="item.value"
							:key="item.value"
							border
							style="width: 180px;margin:0;margin-bottom:10px;display: flex;flex-direction: row;position: relative;"
						>
							{{ item.label }}
							<el-input
								size="small"
								v-if="formAllocation.type == 'distribution'"
								v-model="formAllocation.collect[index]"
								placeholder="分配条数"
								style="position: absolute;left: 200px;top: -2px;"
								@change="getNumber"
							></el-input>
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<div class="dialog-footer myRight">
				<el-button size="small" @click="allocationVisible = false">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getcollectData('formAllocation')">确定</el-button>
			</div>
		</el-dialog>

		<div class="drawer-box" :modal-append-to-body="false" append-to-body>
			<el-drawer title="通话记录" v-model="clueVisible" direction="rtl" size="60%" :before-close="handleClose" modal="false" :with-header="false">
				<div class="calllog-box">
					<div class="title-box">
						<div class="header-left">
							<div class="name" v-if="clueDetails.dataType == 'shop'">{{ clueDetails.shopName }}</div>
							<div class="name" v-if="clueDetails.dataType == 'leads'">{{ clueDetails.companyName }}</div>
							<div class="info">
								<div class="shopName" v-if="clueDetails.dataType == 'shop'">{{ clueDetails.shopName }}</div>
								<div class="shopName" v-if="clueDetails.dataType == 'leads'">{{ clueDetails.companyName }}</div>
								<div class="normalName">跟进人：{{ clueDetails.normalName }}</div>
							</div>
						</div>
						<div class="header-right"></div>
					</div>
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="联系方式" name="contactInformation">
							<el-row>
								<el-col :span="24">
									<el-table
										:data="followUpRecordData"
										border
										class="table"
										ref="multipleTable"
										v-loading="loading"
										header-cell-class-name="table-header"
										@row-click="showData2"
										:header-cell-style="{ 'text-align': 'center' }"
									>
										<el-table-column prop="contactPerson" label="联系人" align="center">
											<template #default="scope">
												{{ scope.row.contactPerson || '无' }}
											</template>
										</el-table-column>
										<el-table-column prop="contactInfo" label="联系号码" align="center"></el-table-column>
										<el-table-column prop="called" label="拨打状态" align="center">
											<template #default="scope">
												<p
													v-if="scope.row.called == false"
													style="background-color: #549DFC;color: #fff;padding: 2px 4px;border-radius: 2px;width: 70px;text-align: center;margin: 0 auto;"
												>
													未拨打
												</p>
												<p
													v-else
													style="background-color: #68D641;color: #fff;padding: 2px 4px;border-radius: 2px;width: 70px;text-align: center;margin: 0 auto;"
												>
													已拨打
												</p>
											</template>
										</el-table-column>
										<el-table-column prop="called" label="操作" align="center" width="190">
											<template #default="scope">
												<el-button type="primary" :class="[theme]" size="small" v-if="scope.row.called == false" @click="handleSocketSend(scope.row, clueDetails)">
													拨打
												</el-button>
												<el-button type="danger" size="small" v-else disabled>已拨打</el-button>
											</template>
										</el-table-column>
									</el-table>
								</el-col>
							</el-row>
						</el-tab-pane>
						<el-tab-pane label="跟进记录" name="callLog">
							<el-timeline-item v-for="(item, index) in historyData" :key="index" :timestamp="item.phoneModel">
								<el-card>
									<div class="history-info">
										<div class="history-phoneNumber">
											<span>{{ item.customerName }}</span>
											<span>{{ item.phoneNumber }}</span>
										</div>
										<div>{{ item.createdAt }}</div>
									</div>
									<div class="history-status">
										<div class="history-phone-status">
											<div>
												<el-tag>{{ item.callStatus }}</el-tag>
												<el-tag type="warning">{{ item.callDuration }}</el-tag>
											</div>
											<div style="overflow:hidden;width: 600px;display: flex;flex-direction: row;">
												<span style="margin-right: 20px;display: inline-block;width: 60px;">通话记录:</span>
												<span>{{ item.remark }}</span>
											</div>
										</div>
										<div class="history-audio">
											<audio :id="item.recordPath" controls="controls" preload="none">
												<source :src="item.recordPath" type="audio/mpeg" />
												<source :src="item.recordPath" type="audio/ogg" />
												<source :src="item.recordPath" type="audio/wav" />
											</audio>
										</div>
									</div>
								</el-card>

								<!-- {{ item.phoneModel }} -->
							</el-timeline-item>
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-drawer>
		</div>

		<el-dialog v-model="dialogIntention" width="650px" :show-close="false" class="dialogIntention" :close-on-click-modal="false">
			<div class="dialog-title">
				<i class="el-icon-s-custom"></i>
				<div class="title" v-if="callStatus == false">正在与{{ phoneNumber }}通话中...</div>
				<div class="title" v-else>与{{ phoneNumber }}通话结束</div>
				<div class="time">通话时长&nbsp;{{ formatSeconds(callDuration) }}</div>
			</div>
			<el-form :model="formIntention" ref="form" label-width="100px">
				<el-form-item label="客户姓名" prop="name"><el-input size="small" v-model="formIntention.name"></el-input></el-form-item>
				<el-form-item label="意向选择" prop="intention">
					<el-radio-group v-model="formIntention.intention">
						<el-radio v-for="item in optionsIntentionList" :key="item.value" :label="item.value" :value="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注">
					<el-input size="small" type="textarea" v-model="formIntention.remark" placeholder="请填写该客户通话备注" :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;">
					<el-button type="primary" :class="[theme]" size="small" @click="getSocketIntention(1)">转入CRM</el-button>
					<el-button type="primary" :class="[theme]" size="small" @click="getblack">加入黑名单</el-button>
					<el-button type="primary" :class="[theme]" size="small" @click="socketForced">强制结束</el-button>
					<el-button type="primary" :class="[theme]" size="small" :disabled="bodadisabled" @click="getSocketIntention">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog v-model="callPopup5" title="黑名单号码" width="450px" :close-on-click-modal="false">
			<div class="call-type5">
				<div class="black-radio">
					<div class="title">原因</div>
					<el-radio-group v-model="blackremark" @change="blackRadioChange">
						<el-radio label="明确表示不再允许打电话">明确表示不再允许打电话</el-radio>
						<el-radio label="有明显投诉倾向">有明显投诉倾向</el-radio>
						<el-radio label="已确认非目标客户">已确认非目标客户</el-radio>
						<el-radio label="其他">其他</el-radio>
					</el-radio-group>
				</div>
				<div class="btn-box">
					<button @click="callPopup5 = false">取消</button>
					<button @click="getblackListAdd">添加</button>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="transferVisible" title="转移数据" width="450px" :close-on-click-modal="false">
			<el-form :model="form" ref="form" label-width="100px">
				<el-form-item label="跟进员工" prop="userId">
					<el-select size="small" v-model="form.userId" placeholder="请选择跟进员工" :disabled="userDis" style="width:100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;">
					<el-button size="small" @click="transferVisible = false">取消</el-button>
					<el-button type="primary" :class="[theme]" size="small" @click="transferPhoneNumberPool">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex.js';
import { fetchAgent } from '../api/agentIndex';
import {
	addCreatePool,
	collectData,
	exportData,
	getPoolURL,
	phoneNumberPoolhistory,
	deletePhoneNumberPool,
	deleteDataPhoneNumberPool,
	transferPhoneNumberPool,
	exportDataPhoneNumberPool,
	findNormal
} from '../api/powerandhealth.js';
import {
	fetchData,
	phoneNumberPoolListFiles,
	phoneNumberPoolDataList,
	socketSend,
	uploedriskNumber,
	getCallStatuss,
	socketIntention,
	blackListAdd,
	socketforced
} from '../api/customerIndex';
import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers, fetchCompany } from '../api/index.js';
import { getUserByDepartmentId } from '../api/assignLeads';
import { addIntentionCustomer } from '../api/recordIndex';
export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				userId: localStorage.getItem('user')
			},
			query2: {
				pageIndex: 1,
				pageSize: 100,
				customerFileId: '',
				normalFileId: '',
				userId: localStorage.getItem('user'),
				called: '',
				companyId: '',
				startTime: '',
				endTime: '',
				normalId: ''
			},
			pageTotal: 0,
			tableData: [],
			companys: [],
			agents: [],
			addVisible: false,
			canAddCompany: true,
			isAdmin: false,
			isNormal: false,
			isAgent: false,
			isrepeat: false,
			iscompany: false,
			isdepartment: false,
			showForm: true, //下拉展开
			drawerVisible: false, //侧边展开详情
			clueVisible: false, //侧边展开详情
			iframeURL: '', //侧边展开的地址
			timeArr: [], //时间
			menu: {
				pageIndex: 1,
				pageSize: 1000
			},
			form: {
				companyId: '',
				contactName: '',
				phoneNumber: '',
				userId: '',
				repeat: true
			},
			formAdd: {
				companyId: '',
				name: '',
				type: ''
			},
			typeList: [
				{
					value: 'shop',
					label: '店铺'
				},
				{
					value: 'leads',
					label: '线索'
				}
			],
			loading: false,
			menuList: [],
			allocationVisible: false, //分配线索弹窗
			formAllocation: {
				currentUserId: '',
				type: 'avg',
				companyPublicDataId: '',
				collect: {},
				departmentList: [], //可分配部门
				nameList: [] //可分配部门
			},
			departmentList: [], //可分配部门
			nameList: [], //可分配员工
			labelArr: [], //选中的员工id
			valueArr: [],
			nameList: [],
			callDuration: 0, //拨打时长
			dialogIntention: false, //拨打电话的弹窗
			phoneNumber: '', //当前拨打的号码
			callStatus: false,
			setIntervals: false,
			formIntention: {
				name: '',
				intention: 'C',
				remark: ''
			},
			optionsIntentionList: [
				{ label: '意向强', value: 'A' },
				{ label: '意向一般', value: 'B' },
				{ label: '无意向', value: 'C' },
				{ label: '需再跟进', value: 'D' },
				{ label: '无需跟进', value: 'E' }
			],
			callPopup5: false,
			blackremark: '',
			publicDataId: '', //当前行的id
			activeName: 'contactInformation',
			clueDetails: '',
			followUpRecordData: [],
			calledKey: false,
			normalIdKey: false,
			historyData: [],
			multipleSelection: '',
			multipleSelectionlen: 0,
			transferVisible: false,
			users: [],
			normalId: '',
			findNormalList: [],
			noDistribution: 0
		};
	},

	created() {
		let role = localStorage.getItem('ms_role');
		if (role === 'normal') {
			this.isNormal = true;
			this.query.companyId = localStorage.getItem('company');
		}
		if (role === 'admin') {
			this.isAdmin = true;
		}
		if (role === 'agent' || role === 'admin') {
			this.isAgent = true;
		}
		if (role == 'company') {
			this.isrepeat = true;
			this.iscompany = true;
			this.query.companyId = localStorage.getItem('company');
		}
		if (role == 'department') {
			this.isdepartment = true;
			this.query.companyId = localStorage.getItem('company');
		}
		if (this.isAdmin) {
			this.init();
		} else if (this.isAgent) {
			this.init();
		} else {
			this.getphoneNumberPoolListFiles();
			this.fetchDepartments('');
		}
		console.log(this.isNormal, 'dddd');
	},
	methods: {
		 showPopover(contacts) {
		    return contacts && contacts.length > 0;
		  },
		getcalled(value) {
			this.query2.called = this.query2.called == value ? '' : value;
			this.calledKey = !this.calledKey;
		},
		getnormalId(label, value) {
			this.normalId = this.normalId == value ? '' : value;
			this.query2.normalId = this.query2.normalId == label ? '' : label;
			this.normalIdKey = !this.normalIdKey;
		},
		getTime() {
			this.timeArr.push(this.query2.startTime);
			this.timeArr.push(this.query2.endTime);
			this.query2.startTime = this.toDateTimeStr(this.timeArr[0]);
			this.query2.endTime = this.toDateTimeStr(this.timeArr[1]);
		},
		getfindNormal() {
			let data = {
				companyPublicDataId: this.query2.customerFileId,
				userId: localStorage.getItem('user')
			};
			findNormal(data).then(res => {
				if (res.code == 200) {
					this.findNormalList = res.data;
					let arr = {
						label: '无分配',
						value: 'undistributed'
					};
					this.findNormalList.unshift(arr);
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getstop() {},
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
				this.query.agentId = this.agents[0].value;
				this.fetchCompanyByAgentIds(this.query.agentId);
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
			});
			fetchDefaultUsers(localStorage.getItem('user')).then(res => {
				this.users = res.data;
			});
		},
		/* 查询部门 */
		fetchDepartments(id) {
			let data = {
				companyId: id,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.departmentList = res.data;
			});
		},
		deleteTab(targetName) {
			let data = {
				id: targetName,
				userId: localStorage.getItem('user')
			};
			// 二次确认删除
			this.$confirm('删除会清空数据池内所有线索数据，确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					deletePhoneNumberPool(data).then(res => {
						if (res.code == 200) {
							this.$message.success(res.message);
							this.getphoneNumberPoolListFiles();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		getphoneNumberPoolListFiles() {
			this.loading = true;

			let data = {
				companyId: this.query.companyId,
				name: this.query.name,
				pageIndex: this.menu.pageIndex,
				pageSize: this.menu.pageSize,
				userId: localStorage.getItem('user')
			};
			console.log(data,'datadata')
			phoneNumberPoolListFiles(data).then(res => {
				this.loading = false;
				if (res.data) {
					this.menuList = res.data.list;
					this.menupageTotal = res.data.total;
					if (this.menuList.length > 0) {
						this.query2.customerFileId = this.menuList[0].id;
						this.clueType = this.menuList[0].type;
						this.getphoneNumberPoolDataList();
						this.getfindNormal();
					}
				}
			});
		},
		/* 获取左侧导航的主节点数据 */
		getphoneNumberPoolDataList() {
			this.tableData = [];
			console.log(this.query2, '1111');
			this.num = '';
			if (this.query2.pageIndex) {
				let data = {
					companyPublicDataId: this.query2.customerFileId,
					userId: localStorage.getItem('user'),
					pageIndex: this.query2.pageIndex,
					pageSize: this.query2.pageSize,
					called: this.query2.called,
					companyId: this.query.companyId,
					startTime: this.query2.startTime,
					endTime: this.query2.endTime,
					normalId: this.normalId
				};
				phoneNumberPoolDataList(data).then(res => {
					if (res.data) {
						this.tableData = res.data.pageHelper.list;
						this.pageTotal = res.data.pageHelper.total;
						this.noDistribution = res.data.noDistribution;
					}
				});
			}
		},
		/* 代理商 */
		agentChange(agentId) {
			this.fetchCompanyByAgentIds(agentId);
		},
		/* 切换代理商企业 */
		fileChange(companyId) {
			this.query.companyId = companyId;
			this.query.departmentId = '';
			this.form.companyId = companyId;
			this.form.departmentId = '';
			this.fetchDepartments(companyId);
			this.getphoneNumberPoolListFiles();
		},
		/* 代理商下的企业 */
		fetchCompanyByAgentIds(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
					this.query.companyId = this.companys[0].value;
					this.fetchDepartments(this.query.companyId);
					this.getphoneNumberPoolListFiles();
				});
			}
		},
		/* 分配数据 */
		allocationData() {
			this.fetchDepartments(this.query.companyId);
			this.allocationVisible = true;
		},
		typeChange(e) {
			this.formAllocation.type = e;
			this.formAllocation.departmentList = [];
			this.formAllocation.nameList = [];
		},
		getNumber(e) {
			this.valueArr.push(e);
		},
		//分配的员工id字符串化
		handleCheckedCitiesChange2(value) {
			this.formAllocation.nameList = value;
			this.labelArr = value;
		},
		handleCheckedCitiesChange(value) {
			this.formAllocation.departmentList = value;
			this.formAllocation.nameList = [];
			if (value) {
				if (value.length > 0) {
					getUserByDepartmentId(value).then(res => {
						if (res.code == 200) {
							this.nameList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					this.nameList = [];
				}
			}
		},
		getcollectData() {
			this.formAllocation.collect = {};
			if (this.formAllocation.type == 'avg') {
				for (let i = 0; i < this.labelArr.length; i++) {
					this.formAllocation.collect[this.labelArr[i]] = 0;
				}
				let data = {
					currentUserId: localStorage.getItem('user'),
					type: this.formAllocation.type,
					companyPublicDataId: this.query2.customerFileId,
					collect: this.formAllocation.collect,
					ids: this.multipleSelection
				};
				collectData(JSON.stringify(data)).then(res => {
					if (res.code == 200) {
						this.$message.success('分配成功');
						this.allocationVisible = false;
						this.getphoneNumberPoolDataList();
					} else {
						this.$message.error(res.message);
					}
				});
			} else {
				for (let i = 0; i < this.labelArr.length; i++) {
					this.formAllocation.collect[this.labelArr[i]] = this.valueArr[i];
				}
				let data = {
					currentUserId: localStorage.getItem('user'),
					type: this.formAllocation.type,
					companyPublicDataId: this.query2.customerFileId,
					collect: this.formAllocation.collect,
					ids: this.multipleSelection
				};
				collectData(JSON.stringify(data)).then(res => {
					if (res.code == 200) {
						this.$message.success('分配成功');
						this.allocationVisible = false;
						this.getphoneNumberPoolDataList();
					} else {
						this.$message.error(res.message);
					}
				});
			}
		},
		handleClickNav(e) {
			console.log(e.instance.vnode, 'ssss');
			this.query2.pageIndex = 1;
			this.clueType = e.instance.vnode.props.key;
			this.query2.companyPublicDataId = e.instance.vnode.props.name;
			this.getphoneNumberPoolDataList();
		},

		// 多选操作
		handleSelectionChange(val) {
			if (val.length > 0) {
				for (let i = 0; i < val.length; i++) {
					this.multipleSelection += val[i].id + ',';
				}
			} else {
				this.multipleSelection = val;
			}
			this.multipleSelectionlen = val.length;
			this.noDistribution = val.length;
		},
		deleteDataPhoneNumberPool() {
			//删除
			let data = {
				ids: this.multipleSelection,
				userId: localStorage.getItem('user')
			};
			deleteDataPhoneNumberPool(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.getphoneNumberPoolListFiles();
				} else {
					this.$message.error(res.message);
				}
			});
		},
		fetchDefaultUsers() {
			if (this.multipleSelectionlen == 0) {
				this.$message.error('请选择转移的数据');
			} else {
				this.transferVisible = true;
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			}
		},
		transferPhoneNumberPool() {
			let data = {
				normalId: this.form.userId,
				ids: this.multipleSelection
			};
			transferPhoneNumberPool(data).then(res => {
				if (res.code == 200) {
					this.transferVisible = false;
					this.getphoneNumberPoolListFiles();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			});
		},
		//导出
		exportDataPhoneNumberPool() {
			this.loading = true;
			if (this.multipleSelectionlen == 0) {
				this.type = 'all';
			} else {
				this.type = 'select';
			}
			let data = {
				ids: this.multipleSelection,
				userId: localStorage.getItem('user'),
				type: this.type,
				companyPublicDataId: this.query2.customerFileId
			};
			exportDataPhoneNumberPool(data).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let fileName = '公海池' + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
				this.loading = false;
			});
		},
		// 分页导航
		handlePageChange(val) {

			this.query2.pageIndex = val;
			this.getphoneNumberPoolDataList();
		},
		handleSizeChange(val){
			this.query2.pageSize = val;
			this.getphoneNumberPoolDataList();
		},
		resetForm(formName) {
			this.addVisible = false;
			(this.form = {
				label: '',
				options: '',
				companyId: '',
				addoptions: ''
			}),
				this.resetForm(formName);
		},
		// 点击取消
		closeDialog(formName) {
			this.addVisible = false;
			this.form = {
				label: ''
			};
		},
		//新建线索池
		addCluePool() {
			this.addVisible = true;
		},
		getaddCreatePool() {
			let data = {
				companyId: this.query.companyId,
				name: this.formAdd.name,
				type: this.formAdd.type
			};
			addCreatePool(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.addVisible = false;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		toDateTimeStr(date) {
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}
			return y + '-' + m + '-' + d;
		},
		getTime() {
			this.timeArr.push(this.query2.startTime);
			this.timeArr.push(this.query2.endTime);
			this.query2.startTime = this.toDateTimeStr(this.timeArr[0]);
			this.query2.endTime = this.toDateTimeStr(this.timeArr[1]);
		},
		// 编辑操作
		handleEdit(row) {
			this.drawerVisible = true;
			this.iframeURL="";
			if (row.dataType == 'leads') {
				let data = {
					userId: localStorage.getItem('user'),
					uid: row.uid,
					type: 'clues/detail'
				};
				getPoolURL(data).then(res => {
					if (res.code == 200) {
						this.iframeURL = res.data;
					} else {
						if (res.message == '该账号还未开通') {
							this.$message.error(res.message);
							setTimeout(() => {
								this.$router.push('/guide');
							}, 1000);
						} else {
							this.$message.error(res.message);
						}
					}
				});
			} else if (row.dataType == 'shop') {
				let data = {
					userId: localStorage.getItem('user'),
					uid: row.uid,
					type: 'clues/shop-detail'
				};
				getPoolURL(data).then(res => {
					if (res.code == 200) {
						this.iframeURL = res.data;
					} else {
						if (res.message == '该账号还未开通') {
							this.$message.error(res.message);
							setTimeout(() => {
								this.$router.push('/guide');
							}, 1000);
						} else {
							this.$message.error(res.message);
						}
					}
				});
			}
		},
		//点击当前行
		showData(row) {
			this.clueVisible = true;
			this.clueDetails = row;
			console.log(this.clueDetails, 'ddd ');
			this.activeName = 'contactInformation';
			this.followUpRecordData = row.contacts;
			this.publicDataId = row.id;
		},
		handleClick(e) {
			if (e.props.label == '跟进记录') {
				this.phoneNumberPoolhistorys();
			}
		},
		phoneNumberPoolhistorys() {
			let data = {
				id: this.publicDataId
			};
			phoneNumberPoolhistory(data).then(res => {
				if (res.code == 200) {
					this.historyData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		handleSocketSend(row, rows) {
			console.log(row, 'rowwww');
			console.log(rows, 'rowsrowsrowsrows');
			let username = localStorage.getItem('ms_username');
			this.publicDataId = rows.id;
			let data = {
				name: username,
				info: row.contactInfo,
				type: 3,
				protectionLevel: localStorage.getItem('protectionLevel').toString()
			};
			socketSend(data).then(res => {
				if (res.code == 200) {
					this.bodadisabled = false;
					this.phoneNumber = row.contactInfo;
					if (res.data == 2) {
						this.dialogIntention = true;
						this.getCallStatus();
						this.timer = setInterval(() => {
							this.callDuration++;
						}, 1000);
					} else if (res.data == 1) {
						this.$message.error('请APP重新进入电脑快拨页面');
						localStorage.removeItem('deviceName');
						setTimeout(() => {
							this.$router.push('/highSeas');
						}, 2000);
					} else if (res.data == 0) {
						let text = '此号码为“风险号码”，有历史投诉记录或当天接到多次营销电话，有极高投诉风险，禁止拨打！';
						let msg = this.phoneNumber + text;
						this.$message.error(msg);
						this.getuploedriskNumber();
					} else if (res.data == 3) {
						let text = '号码已被拉入黑名单,不可拨打';
						let msg = this.phoneNumber + text;
						this.$message.error(msg);
					}
				} else {
					this.$message.error(res.message);
				}
			});
		},
		// 保存风险号码
		getuploedriskNumber() {
			let data = {
				phoneNumber: this.phoneNumber
			};
			uploedriskNumber(data).then(res => {
				if (res.code == 200) {
					// this.$message.success('添加成功');
				} else {
					this.$message.error(res.message);
				}
				this.addVisible = false;
			});
		},
		getCallStatus() {
			getCallStatuss(localStorage.getItem('ms_username')).then(res => {
				if (res.code == 200) {
					this.callStatus = res.data.status; //this.callStatus==true  执行拨打下一次电话
					if (res.data.status == true) {
						this.callDuration = res.data.callDuration; //通话时长
						clearInterval(this.timer);
						this.timer = null;
						if (this.zidongbohao) {
							//如果是自动拨号 倒计时拨打  所以说问题是什么
							this.setSecondstimer = setInterval(() => {
								if (this.setSeconds > 0) {
									//设置的间隔时间
									this.setSeconds--;
								} else {
									//如果时间==0了 就执行自动拨打  拨打下一个
									this.intention = 'C: 无意向';
									this.setSeconds = localStorage.getItem('setSeconds');
									this.callDuration = 0;
									this.callStatus = false;
									clearInterval(this.setSecondstimer);
									this.setSecondstimer = null;
									this.setSeconds = localStorage.getItem('setSeconds'); //重新获取间隔拨打时间
									this.formIntention.intention = 'C';
									this.formIntention.remark = '';
									if (this.arrI < this.tableData.length) {
										if (this.setSeconds > 0) {
											this.call();
											this.dialogIntention = false;
										}
									} else {
										this.dialogIntention = false;
										this.$message.error('请查看号码列表是否已拨打完');
										this.getcustomerFile();
										this.getphoneNumberPoolDataList();
									}
								}
							}, 1000);
						}
					} else {
						setTimeout(() => {
							this.getCallStatus();
						}, 1000);
					}
				}
			});
		},
		formatSeconds(result) {
			var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
			var m = Math.floor((result / 60) % 60) < 10 ? '0' + Math.floor((result / 60) % 60) : Math.floor((result / 60) % 60);
			var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
			if (h > 0) {
				return (result = h + ':' + m + ':' + s);
			} else {
				return (result = m + ':' + s);
			}
		},
		getSocketIntention(e) {
			this.bodadisabled = true;
			let username = localStorage.getItem('ms_username');
			let data = {
				name: username,
				info: this.formIntention.intention,
				type: 4,
				remark: this.formIntention.remark,
				phoneNumber: this.phoneNumber,
				customerName: this.formIntention.name,
				publicDataId: this.publicDataId
			};
			socketIntention(data).then(res => {
				this.bodadisabled = false;
				if (res.code == 500) {
					this.$message.error(res.message);
				} else if (res.code == 200) {
					if (res.data == true) {
						if (e == 1) {
							this.getAddIntentionCustomer();
						} else {
							this.$message.success(res.message);
							this.dialogIntention = false;
							this.callDuration = 0;
							this.callStatus = false;
							clearInterval(this.timer);
							this.timer = null;
							this.formIntention.intention = 'C';
							this.formIntention.remark = '';

							this.phone = ''; //为了手动拨号后input清空
							this.phoneNumber = ''; //为了手动拨号后input清空
							this.getphoneNumberPoolDataList();
						}
					} else if (res.data == false) {
						this.$message.error('请挂断电话后操作');
						this.getphoneNumberPoolDataList();
					}
				}
			});
		},
		//转入crm
		getAddIntentionCustomer() {
			let data = {
				customerName: this.formIntention.name,
				userId: localStorage.getItem('user'),
				phoneNumber: this.phoneNumber,
				remark: this.formIntention.remark,
				source: '手动添加',
				normalName: localStorage.getItem('user')
			};
			addIntentionCustomer(JSON.stringify(data)).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.dialogIntention = false;
					this.callDuration = 0;
					this.callStatus = false;
					clearInterval(this.setSecondstimer);
					this.setSecondstimer = null;
					this.setSeconds = localStorage.getItem('setSeconds'); //重新获取间隔拨打时间
					clearInterval(this.timer);
					this.timer = null;
					this.formIntention.intention = 'C';
					this.formIntention.remark = '';
					this.phone = ''; //为了手动拨号后input清空
					this.phoneNumber = ''; //为了手动拨号后input清空
					this.getphoneNumberPoolDataList();
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getblack() {
			this.blackremark = '其他';
			this.callPopup5 = true;
		},
		//黑名单选项
		blackRadioChange(evt) {
			this.blackremark = evt;
		},
		getblackListAdd() {
			let data = {
				userId: localStorage.getItem('user'),
				phoneNumber: this.phoneNumber,
				remark: this.blackremark
			};
			blackListAdd(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.callPopup5 = false;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		socketForced() {
			let data = {
				userId: localStorage.getItem('user'),
				userPhone: localStorage.getItem('ms_username'),
				phoneNumber: this.phoneNumber,
				intention: this.formIntention.intention,
				remark: this.formIntention.remark
			};
			socketforced(data).then(res => {
				if (res.code == 200) {
					clearInterval(this.timer);
					this.timer = null;
					clearInterval(this.setSecondstimer);
					this.setSecondstimer = null;
					this.getphoneNumberPoolDataList();
					this.$message.success(res.message);
					this.dialogIntention = false;
					this.callStatus = false;
					this.arrI = 0;
					this.setSeconds = localStorage.getItem('setSeconds'); //重新获取间隔拨打时间
					this.formIntention.intention = 'C';
					this.formIntention.remark = '';
					this.phoneNumber = '';
				} else {
					this.$message.error(res.message);
				}
			});
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.el-form-item__label {
	font-size: 13px !important;
}

.hide .el-upload--picture-card {
	display: none;
}
.content_top {
	display: flex;
	justify-content: space-around;
}
.content_bottom {
	display: flex;
	justify-content: space-around;
}
.content_middle {
	display: flex;
	margin: 0.625rem 0 0.625rem 0;
	justify-content: space-around;
}
.ywlx:hover {
	color: #00a854;
	background: none;
}
.content_top {
	display: flex;
	margin: auto;
}

.loyo-folding-btn:hover {
	border-color: #2c9dfc;
}
.loyo-folding-btn {
	position: relative;
	color: #2c9dfc;
	height: 20px;
	text-align: center;
	border-top: 1px solid #e5e9f0;
	margin-top: 15px;
	cursor: pointer;
}
.loyo-folding-btn a {
	position: absolute;
	bottom: -1px;
	border-bottom: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-top: 3px solid #ddd;
	border-top-color: #fff;
	width: 60px;
	height: 20px;
	line-height: 20px;
}
.loyo-folding-btn a i {
	font-size: 18px;
	color: #ddd;
}
.loyo-folding-btn:hover i {
	color: #2c9dfc;
}
.loyo-folding-btn:hover a {
	border-bottom: 1px solid #2c9dfc;
	border-left: 1px solid #2c9dfc;
	border-right: 1px solid #2c9dfc;
	border-top: 3px solid #2c9dfc;
	border-top-color: #fff;
}
.contacts-box {
	cursor: pointer;
}
.contacts-box .contacts-info {
	width: 350px;
	max-height: 500px;
	padding: 8px 6px;
	display: none;
	z-index: 8888;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
	border: 1px solid #ccc;
	position: absolute;
	top: 39px;
	left: 65px;
	border-radius: 5px;
	overflow-y: auto;

}

.big {
	width: 0px;
	height: 0px;
	border-width: 10px 10px 0 10px;
	border-style: solid;
	border-color: #ccc transparent transparent transparent;
	position: relative;
	transform: rotate(180deg);
	top: -5px;
	left: 65px;
	z-index: 9999;
	opacity: 0;
}
.small {
	width: 0px;
	height: 0px;
	border-width: 10px 10px 0 10px;
	border-style: solid;
	border-color: #fff transparent transparent transparent;
	position: absolute;
	bottom: 1px;
	left: -10px;
}

.contacts-box:hover .big {
	opacity: 1;
}
.contacts-box:hover .contacts-info {
	display: block;
}

.contacts-phone {
	display: flex;
	flex-direction: column;
	text-align: left;
}
.contacts-phone .phone{
	display: flex;
	flex-direction: row;
	
}
.contacts-box .contacts-info img {
	width: 30px;
	height: 30px;
	margin-left: 10px;
}

/deep/.el-table__body {
	padding-bottom: 520px;
}
.el-dialog .el-dialog__body .dialog-title {
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	text-align: center !important;
	position: relative !important;
	top: -34px !important;
}
.el-dialog .el-dialog__body .dialog-title i {
	font-size: 50px !important;
	color: #007bfe !important;
}
.el-dialog .el-dialog__body .dialog-title .title {
	font-size: 22px;
	font-weight: 500;
	margin: 6px 0;
}
.el-dialog .el-dialog__body .dialog-title .time {
	font-weight: 500;
	font-size: 20px;
	color: rgb(16, 142, 233);
}
.call-type5 {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.call-type5 .input-box {
	height: 60px;
	border-bottom: 1px solid #ccc;
}
.call-type5 .black-radio {
	position: relative;
	top: -15px;
	border: 1px solid #007bfe;
	padding: 10px;
}
.call-type5 .black-radio .title {
	font-size: 16px;
	color: #000;
}
.call-type5 .black-radio .el-radio-group {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}
.call-type5 .black-radio .el-radio-group .el-radio {
	margin: 6px 2px;
}
.call-type5 .btn-box {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 30px;
}
.call-type5 .btn-box button {
	width: 84px;
	height: 34px;
	margin: 0;
	padding: 0;
	line-height: 34px;
	text-align: center;
	border-radius: 8px;
	color: #fff;
	font-size: 16px;
	background-color: #007bfe;
	border: none;
}

.calllog-box {
	padding: 20px 16px;
}
.title-box {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 20px;
}
.title-box i {
	color: #66b1ff;
	font-size: 30px;
	font-weight: 600;
	margin-right: 20px;
	cursor: pointer;
}
.title-box .header-left {
	display: flex;
	flex-direction: row;
}
.title-box .header-left .name {
	width: 80px;
	height: 80px;
	border-radius: 16px;
	background-color: aquamarine;
	font-size: 30px;
	text-align: center;
	padding: 6px;
	overflow: hidden;
	margin-right: 20px;
	font-weight: 600;
	word-wrap: break-word;
	word-break: normal;
}
.title-box .header-left .info {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}
.title-box .header-left .info .shopName {
	font-size: 28px;
	color: #000;
	font-weight: 600;
}
.title-box .header-left .info .normalName {
	font-size: 16px;
}

.history-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.history-info .history-phoneNumber span {
	margin-right: 20px;
}
.history-status {
	display: flex;
	flex-direction: row;
	margin: 16px 0;
	justify-content: space-between;
}
.history-status .history-phone-status div {
	margin-bottom: 10px;
}
.history-status .history-phone-status .el-tag {
	margin-right: 20px;
}
/deep/.el-tabs__item {
	padding: 0 40px !important;
	height: 55px !important;
	line-height: 55px !important;
}

/deep/.el-tabs__nav-wrap.is-scrollable {
	padding: 0 44px !important;
}
/deep/.el-tabs__nav-next,
/deep/.el-tabs__nav-prev {
	line-height: 55px !important;
	width: 44px;
	text-align: center;
	background-color: #ccc;
	color: #fff;
	opacity: 0.8;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
	border-bottom-color: rgb(84, 157, 252);
}

.data-pool-operation {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #f3f3f3;
	padding: 10px 20px;
	margin: 20px 0;
}
.data-pool-operation span {
	color: #71a7a0;
	margin: 0 4px;
}
.data-pool-operation .el-button {
	margin-left: 20px;
}
</style>
